import { useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { usePrefersDarkMode } from './usePrefersDarkMode';

/*
 * Refactored this from the Wordle Tailwind CSS and followed the pattern found
 * here https://usehooks.com/useDarkMode/
 * */
export const useDarkMode = ({
  darkModeClassName = 'dark',
  darkModeStorageKey = 'dark-mode-enabled',
} = {}) => {
  const [enabledState, setEnabledState] = useLocalStorage<boolean>(
    darkModeStorageKey,
    false
  );
  // See if user has set a browser or OS preference for dark mode.
  // The usePrefersDarkMode hook composes a useMedia hook (see code below).
  const prefersDarkMode = usePrefersDarkMode();
  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const enabled = enabledState ?? prefersDarkMode;
  // Fire off effect that add/removes dark mode class
  useEffect(() => {
    const className = darkModeClassName;
    // const element = window.document.body;
    const element = document.documentElement;
    if (enabled) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }, [enabled, darkModeClassName]);
  // Return enabled state and setter
  return [enabled, setEnabledState] as const;
};
