import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Grid } from './components/grid/Grid';
import { Keyboard } from './components/keyboard/Keyboard';
import { InfoModal } from './components/modals/InfoModal';
import { StatsModal } from './components/modals/StatsModal';
import { SettingsModal } from './components/modals/SettingsModal';
import {
  CORRECT_WORD_MESSAGE,
  GAME_COPIED_MESSAGE,
  GAME_TITLE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings';
import {
  ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
} from './constants/settings';
import {
  findFirstUnusedReveal,
  isWinningWord,
  isWordInWordList,
} from './lib/words';
import { addStatsForCompletedGame, loadStats } from './lib/stats';
import {
  clearGameState,
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  loadHasSeenInfoModal,
  saveGameStateToLocalStorage,
  setHasSeenInfoModal,
  setStoredIsHighContrastMode,
} from './lib/localStorage';

import { useDarkMode } from './hooks/useDarkMode';
import { AlertContainer } from './components/alerts/AlertContainer';
import { useAlert } from './context/AlertContext';
import { SuccessModal } from './components/modals/SuccessModal';
import { useActiveSolution } from './context/ActiveSolutionContext';
import ReactGA from 'react-ga4';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

function Wordle() {
  const { solution, hint, slug } = useActiveSolution();
  const [darkMode, setDarkMode] = useDarkMode();
  const { width, height } = useWindowSize();

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert();
  const [currentGuess, setCurrentGuess] = useState('');
  const [isGameWon, setIsGameWon] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [currentRowClass, setCurrentRowClass] = useState('');
  const [isGameLost, setIsGameLost] = useState(false);
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  );

  const clearGameStateToRetry = () => {
    clearGameState(slug);
    window.location.reload();
  };

  const [isRevealing, setIsRevealing] = useState(false);
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(slug);
    if (loaded?.solution !== solution) {
      return [];
    }
    const gameWasWon = loaded.guesses.includes(solution);
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true);
      // showErrorAlert(CORRECT_WORD_MESSAGE(hint), {
      //   persist: true,
      // });
      showErrorAlert(CORRECT_WORD_MESSAGE(hint), {
        persist: true,
        delayMs: REVEAL_TIME_MS * solution.length + 1,
        action: () => {
          return clearGameStateToRetry;
        },
      });
    }
    return loaded.guesses;
  });

  const [stats, setStats] = useState(() => loadStats());

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  );

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadHasSeenInfoModal()) {
      setIsInfoModalOpen(true);
      setHasSeenInfoModal(true);
    }
  }, []);

  useEffect(() => {
    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast');
    } else {
      document.documentElement.classList.remove('high-contrast');
    }
  }, [isHighContrastMode]);

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard);
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal');
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE);
    }
  };

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast);
    setStoredIsHighContrastMode(isHighContrast);
  };

  useEffect(() => {
    saveGameStateToLocalStorage(slug, { guesses, solution });
  }, [slug, guesses, solution]);

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)];
      const delayMs = REVEAL_TIME_MS * solution.length;

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsSuccessModalOpen(true),
      });
    }

    // if (isGameLost) {
    //   setTimeout(() => {
    //     setIsStatsModalOpen(true)
    //   }, GAME_LOST_INFO_DELAY)
    // }
  }, [solution.length, isGameWon, isGameLost, showSuccessAlert]);

  const onChar = (value: string) => {
    if (
      currentGuess.length < solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    ReactGA.event('wordle_guess', {
      guess: currentGuess,
      slug,
    });

    if (isGameWon || isGameLost) {
      return;
    }
    if (!(currentGuess.length === solution.length)) {
      showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE);
      setCurrentRowClass('jiggle');
      return setTimeout(() => {
        setCurrentRowClass('');
      }, ALERT_TIME_MS);
    }

    if (!isWordInWordList(currentGuess)) {
      showErrorAlert(WORD_NOT_FOUND_MESSAGE);
      setCurrentRowClass('jiggle');
      return setTimeout(() => {
        setCurrentRowClass('');
      }, ALERT_TIME_MS);
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(
        currentGuess,
        guesses,
        solution
      );
      if (firstMissingReveal) {
        showErrorAlert(firstMissingReveal);
        setCurrentRowClass('jiggle');
        return setTimeout(() => {
          setCurrentRowClass('');
        }, ALERT_TIME_MS);
      }
    }

    setIsRevealing(true);
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false);
    }, REVEAL_TIME_MS * solution.length);

    const winningWord = isWinningWord(currentGuess, solution);

    if (
      currentGuess.length === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess('');

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length));
        return setIsGameWon(true);
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1));
        setIsGameLost(true);
        showErrorAlert(CORRECT_WORD_MESSAGE(hint), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
          action: () => {
            return clearGameStateToRetry;
          },
        });
      }
    }
  };

  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-0">
        <h1 className="text-xl ml-2.5 grow font-bold">
          {GAME_TITLE}
          <br />
          {slug}
        </h1>
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        <CogIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsSettingsModalOpen(true)}
        />
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        handleClose={() => setIsSuccessModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        isHardMode={isHardMode}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={darkMode}
        handleDarkMode={setDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />
      {isSuccessModalOpen && (
        <Confetti width={width ?? 100} height={height ?? 100} />
      )}
      <AlertContainer />
    </div>
  );
}

export default Wordle;
