export const VALID_GUESSES = [
  'bestman',
  'abalone',
  'abandon',
  'ability',
  'abolish',
  'abdomen',
  'abraham',
  'abyssal',
  'academy',
  'account',
  'achieve',
  'acidity',
  'acquire',
  'acrobat',
  'acronym',
  'acrylic',
  'actress',
  'adaptor',
  'address',
  'adjourn',
  'admiral',
  'advance',
  'advisor',
  'aerator',
  'aerosol',
  'affable',
  'afflict',
  'affront',
  'african',
  'against',
  'ageless',
  'agendum',
  'agility',
  'agonize',
  'ailment',
  'airdrop',
  'airfare',
  'airflow',
  'airfoil',
  'airhead',
  'airlift',
  'airline',
  'airmail',
  'airplay',
  'airport',
  'airship',
  'airshow',
  'airsick',
  'airtime',
  'airwave',
  'alamode',
  'alchemy',
  'alcohol',
  'alfalfa',
  'algebra',
  'alfredo',
  'alimony',
  'alkalic',
  'allergy',
  'almanac',
  'already',
  'alright',
  'alumnus',
  'alveoli',
  'amadeus',
  'amateur',
  'amazing',
  'ambient',
  'ambling',
  'amenity',
  'america',
  'amiable',
  'ammonia',
  'amnesia',
  'amnesty',
  'amplify',
  'anagram',
  'analogy',
  'analyst',
  'analyze',
  'anarchy',
  'anatomy',
  'anchovy',
  'ancient',
  'android',
  'anemone',
  'angelic',
  'angrier',
  'angrily',
  'anguish',
  'angular',
  'animals',
  'animate',
  'annuity',
  'answers',
  'antacid',
  'antenna',
  'anthill',
  'anthrax',
  'antifog',
  'antique',
  'antonym',
  'anybody',
  'anymore',
  'anytime',
  'anxiety',
  'anxious',
  'apology',
  'apostle',
  'appease',
  'applaud',
  'appoint',
  'approve',
  'apricot',
  'aquatic',
  'aquifer',
  'archery',
  'archive',
  'archway',
  'arduous',
  'armband',
  'arousal',
  'arraign',
  'arrange',
  'arrival',
  'artists',
  'artwork',
  'ascetic',
  'ashtray',
  'asphalt',
  'aspirin',
  'assault',
  'astride',
  'atheist',
  'athlete',
  'attempt',
  'attract',
  'auction',
  'audible',
  'augment',
  'austria',
  'autopsy',
  'average',
  'aviator',
  'avocado',
  'awaking',
  'awesome',
  'awkward',
  'babysit',
  'backlit',
  'backlog',
  'backrub',
  'badland',
  'baggage',
  'bailout',
  'balance',
  'ballboy',
  'balloon',
  'baloney',
  'bananas',
  'bandage',
  'bandaid',
  'bangkok',
  'bargain',
  'bashful',
  'bastard',
  'battery',
  'beatles',
  'bedroom',
  'beehive',
  'believe',
  'beneath',
  'benefit',
  'berries',
  'between',
  'bicycle',
  'bifocal',
  'billion',
  'bipolar',
  'biscuit',
  'blocker',
  'blossom',
  'blowout',
  'bluejay',
  'blueray',
  'boycott',
  'bravery',
  'breaker',
  'brewery',
  'british',
  'brownie',
  'browser',
  'buffalo',
  'builder',
  'bulldog',
  'burgers',
  'burnout',
  'burrito',
  'cabinet',
  'calcium',
  'camelot',
  'campain',
  'capital',
  'capitol',
  'captain',
  'caption',
  'capture',
  'caravan',
  'carbarn',
  'cardoor',
  'careers',
  'careful',
  'caribou',
  'carkeys',
  'carrier',
  'cartoon',
  'cascade',
  'cassidy',
  'catfish',
  'caution',
  'central',
  'century',
  'certain',
  'chalice',
  'chamber',
  'changes',
  'channel',
  'chapter',
  'charger',
  'charity',
  'charlie',
  'cheaper',
  'cheater',
  'checker',
  'checkup',
  'cheddar',
  'cheerio',
  'cheetah',
  'cherish',
  'chicken',
  'chimney',
  'chipper',
  'choices',
  'choosey',
  'chopper',
  'chorale',
  'chowder',
  'circuit',
  'classes',
  'classic',
  'cleaner',
  'clinton',
  'closely',
  'clothes',
  'cockpit',
  'coconut',
  'coldest',
  'collage',
  'collect',
  'college',
  'colonel',
  'combine',
  'combust',
  'comedic',
  'comfort',
  'comical',
  'command',
  'commend',
  'comment',
  'commode',
  'commune',
  'commute',
  'compact',
  'company',
  'compare',
  'compass',
  'compete',
  'compile',
  'complex',
  'compose',
  'compute',
  'comrade',
  'concave',
  'conceal',
  'concede',
  'conceit',
  'concent',
  'concept',
  'concern',
  'concert',
  'concise',
  'concord',
  'condemn',
  'condone',
  'conduct',
  'conduit',
  'confess',
  'confide',
  'confine',
  'confirm',
  'conform',
  'confuse',
  'congeal',
  'conical',
  'conifer',
  'conjoin',
  'conjure',
  'connect',
  'conquer',
  'console',
  'consort',
  'consult',
  'consume',
  'contact',
  'contain',
  'contend',
  'content',
  'contest',
  'context',
  'contour',
  'control',
  'convent',
  'convict',
  'cookies',
  'correct',
  'corrupt',
  'costume',
  'cottage',
  'council',
  'counter',
  'country',
  'couples',
  'courage',
  'cowgirl',
  'cowhide',
  'cracker',
  'crapper',
  'crawler',
  'crazily',
  'creator',
  'crewcut',
  'crimson',
  'cripple',
  'crouton',
  'crucify',
  'cruelty',
  'cruiser',
  'crunchy',
  'crusade',
  'crybaby',
  'crystal',
  'cubicle',
  'cuisine',
  'culprit',
  'culture',
  'culvert',
  'cumulus',
  'cupcake',
  'cuplike',
  'curator',
  'curious',
  'current',
  'cursive',
  'curtail',
  'curtain',
  'cushion',
  'custard',
  'custody',
  'cutback',
  'cuticle',
  'cutlery',
  'cutlets',
  'cyclone',
  'cynical',
  'daycare',
  'daytime',
  'decades',
  'deceive',
  'declare',
  'decibel',
  'decimal',
  'declare',
  'decline',
  'decoder',
  'decorum',
  'default',
  'defence',
  'defiant',
  'deficit',
  'deflate',
  'defrost',
  'defuser',
  'degrade',
  'degrees',
  'delight',
  'deliver',
  'deltoid',
  'demerit',
  'denmark',
  'density',
  'dentist',
  'denture',
  'deplete',
  'deplore',
  'deposit',
  'depress',
  'deprive',
  'dervish',
  'descent',
  'deserve',
  'desktop',
  'despair',
  'despite',
  'despond',
  'dessert',
  'destain',
  'destiny',
  'destroy',
  'detract',
  'develop',
  'devilry',
  'devious',
  'dewdrop',
  'diamond',
  'diapers',
  'diction',
  'diecast',
  'digital',
  'dignity',
  'disable',
  'disavow',
  'disband',
  'discard',
  'discern',
  'discord',
  'discuss',
  'disdain',
  'disease',
  'diseuse',
  'disgust',
  'disjoin',
  'dislike',
  'dismast',
  'dismiss',
  'disobey',
  'dispart',
  'display',
  'disport',
  'dispose',
  'dispute',
  'disrate',
  'disrobe',
  'disrupt',
  'disseat',
  'distort',
  'disturb',
  'dissect',
  'distant',
  'distend',
  'distich',
  'distill',
  'diverse',
  'divided',
  'divorce',
  'doctors',
  'dollars',
  'dolphin',
  'doorway',
  'dormant',
  'draught',
  'dreamer',
  'drivers',
  'dryness',
  'durable',
  'dynamic',
  'earache',
  'earlier',
  'earlobe',
  'eastern',
  'eclipse',
  'economy',
  'educate',
  'egotism',
  'elastic',
  'elderly',
  'elegant',
  'element',
  'elevate',
  'embassy',
  'embrace',
  'emotion',
  'empathy',
  'empress',
  'enclose',
  'endless',
  'enforce',
  'english',
  'enhance',
  'enlight',
  'envious',
  'eternal',
  'ethical',
  'equinox',
  'equator',
  'evasive',
  'exactly',
  'example',
  'excited',
  'expense',
  'experts',
  'explain',
  'explode',
  'explore',
  'express',
  'extinct',
  'extract',
  'extreme',
  'eyebrow',
  'eyelash',
  'faceoff',
  'factoid',
  'factors',
  'faculty',
  'failure',
  'fanclub',
  'fantasy',
  'farmboy',
  'farmers',
  'fashion',
  'fastest',
  'fatigue',
  'feature',
  'federal',
  'ferment',
  'ferried',
  'fiction',
  'fifteen',
  'fighter',
  'finally',
  'finance',
  'firedog',
  'firefly',
  'fireman',
  'fitness',
  'fixture',
  'flowers',
  'flulike',
  'flushot',
  'foolish',
  'footage',
  'forbear',
  'forearm',
  'foreign',
  'forever',
  'foreman',
  'forfeit',
  'forgive',
  'formula',
  'forsake',
  'fortune',
  'forward',
  'foxhole',
  'fragile',
  'frantic',
  'freezer',
  'freckle',
  'freedom',
  'freeway',
  'freight',
  'freshly',
  'friends',
  'frisbee',
  'fritter',
  'fumbled',
  'further',
  'gambler',
  'gameboy',
  'garages',
  'garbage',
  'gashose',
  'gastank',
  'gateway',
  'general',
  'genesis',
  'generic',
  'genetic',
  'getaway',
  'giraffe',
  'glimpse',
  'glorify',
  'glucose',
  'goodbye',
  'gorilla',
  'governs',
  'gradual',
  'grammar',
  'grandma',
  'grandpa',
  'granite',
  'granola',
  'graphic',
  'gravity',
  'grocery',
  'habitat',
  'haircut',
  'halibut',
  'happily',
  'harbour',
  'harding',
  'harvest',
  'hatchet',
  'hateful',
  'healthy',
  'heights',
  'helpful',
  'heroism',
  'herself',
  'highest',
  'highway',
  'himself',
  'history',
  'holiday',
  'holland',
  'homeboy',
  'honesty',
  'hopeful',
  'hotcake',
  'hotdogs',
  'husband',
  'hydrant',
  'iceberg',
  'iceland',
  'icicles',
  'ideally',
  'idolize',
  'illicit',
  'illness',
  'imagine',
  'impound',
  'impress',
  'improve',
  'inboard',
  'inbound',
  'incense',
  'incisor',
  'incline',
  'inclose',
  'include',
  'incomes',
  'incubus',
  'indoors',
  'indulge',
  'infancy',
  'inferno',
  'infidel',
  'inflame',
  'inflate',
  'inflict',
  'ingrain',
  'ingrown',
  'inhaler',
  'inherit',
  'initial',
  'inkblot',
  'inkling',
  'inkwell',
  'inlayer',
  'inquire',
  'inquiry',
  'insects',
  'insider',
  'insight',
  'inspect',
  'inspire',
  'install',
  'instant',
  'instead',
  'instill',
  'insular',
  'insurer',
  'intense',
  'interim',
  'interns',
  'introit',
  'intrude',
  'intrust',
  'intwine',
  'invader',
  'invoice',
  'involve',
  'islamic',
  'islands',
  'isolate',
  'itemize',
  'jamaica',
  'january',
  'jasmine',
  'jealous',
  'jewelry',
  'journey',
  'justice',
  'justify',
  'kennedy',
  'keyhole',
  'keynote',
  'kingdom',
  'kinship',
  'kitchen',
  'kittens',
  'kneecap',
  'lantern',
  'laundry',
  'lawsuit',
  'lawyers',
  'leaders',
  'learner',
  'leather',
  'lenscap',
  'lessons',
  'letters',
  'liberal',
  'liberty',
  'library',
  'license',
  'lincoln',
  'lipread',
  'literal',
  'livable',
  'lobster',
  'logical',
  'lovable',
  'lovebug',
  'lullaby',
  'machine',
  'madison',
  'madness',
  'magical',
  'magnify',
  'mailbag',
  'mailbox',
  'mailman',
  'majesty',
  'malaria',
  'maldive',
  'manager',
  'mandate',
  'manners',
  'markers',
  'married',
  'massage',
  'massive',
  'mastery',
  'meander',
  'measure',
  'medical',
  'members',
  'menthol',
  'mercury',
  'mermaid',
  'message',
  'methane',
  'michael',
  'midterm',
  'migrant',
  'migrate',
  'militia',
  'million',
  'misdial',
  'misfile',
  'mislead',
  'misread',
  'missile',
  'mission',
  'mistake',
  'mixture',
  'mobster',
  'modesty',
  'monarch',
  'monitor',
  'monolog',
  'monsoon',
  'monster',
  'montage',
  'monthly',
  'moocher',
  'moonlit',
  'morally',
  'morning',
  'moronic',
  'mortify',
  'mortise',
  'mudflap',
  'mudflow',
  'muffler',
  'muffins',
  'mundane',
  'musical',
  'mustang',
  'mustard',
  'mystery',
  'mystify',
  'nametag',
  'napkins',
  'narcism',
  'nations',
  'natives',
  'natural',
  'naughty',
  'nearest',
  'nebular',
  'necktie',
  'needles',
  'neglect',
  'nervous',
  'network',
  'neurons',
  'neutral',
  'neutron',
  'newborn',
  'newsboy',
  'niagera',
  'nickels',
  'nightly',
  'nirvana',
  'nitrate',
  'nocturn',
  'noisily',
  'nomadic',
  'nominal',
  'nonstop',
  'nostril',
  'notable',
  'notably',
  'nothing',
  'novelty',
  'noxious',
  'nuclear',
  'nucleus',
  'nudnick',
  'nullify',
  'numbers',
  'numeral',
  'nunlike',
  'nunnery',
  'nursery',
  'nurture',
  'nutcase',
  'obesity',
  'observe',
  'obvious',
  'octagon',
  'octopus',
  'october',
  'offense',
  'operate',
  'opinion',
  'organic',
  'ottoman',
  'outback',
  'outcast',
  'outcome',
  'outdoor',
  'outrage',
  'outside',
  'overall',
  'package',
  'painful',
  'painter',
  'pajamas',
  'pandora',
  'panther',
  'paradox',
  'parents',
  'parties',
  'partner',
  'passion',
  'patient',
  'patriot',
  'payment',
  'peanuts',
  'penalty',
  'pennies',
  'pension',
  'percent',
  'perfect',
  'perform',
  'perfume',
  'persist',
  'phonics',
  'phrases',
  'physics',
  'picture',
  'pigears',
  'pigtail',
  'pilgrim',
  'pinball',
  'pintail',
  'pioneer',
  'plastic',
  'playful',
  'plunder',
  'politic',
  'pollute',
  'popcorn',
  'poptart',
  'popular',
  'postage',
  'postbox',
  'postman',
  'praises',
  'prebake',
  'preboil',
  'precede',
  'precise',
  'predict',
  'preempt',
  'preface',
  'preheat',
  'preload',
  'prelude',
  'premade',
  'premier',
  'premise',
  'premium',
  'prepaid',
  'prepare',
  'preppie',
  'prequel',
  'presale',
  'present',
  'preshow',
  'presoak',
  'presume',
  'preteen',
  'pretend',
  'pretest',
  'pretzel',
  'prevail',
  'prevent',
  'preverb',
  'preview',
  'prewash',
  'preworn',
  'preying',
  'pricier',
  'prickly',
  'primage',
  'primary',
  'primate',
  'printer',
  'privacy',
  'private',
  'privies',
  'probate',
  'problem',
  'proceed',
  'process',
  'proctor',
  'procure',
  'prodigy',
  'produce',
  'product',
  'profane',
  'profess',
  'profile',
  'profuse',
  'progeny',
  'program',
  'project',
  'prolate',
  'proline',
  'prolong',
  'promise',
  'promote',
  'pronate',
  'pronoun',
  'propane',
  'prophet',
  'propose',
  'prorate',
  'prosody',
  'prosper',
  'protect',
  'protein',
  'protest',
  'protons',
  'proudly',
  'proverb',
  'provide',
  'provoke',
  'provost',
  'prowess',
  'prowler',
  'proxies',
  'prudent',
  'puberty',
  'publish',
  'pumpkin',
  'puppies',
  'purpose',
  'pursuit',
  'pyramid',
  'qualify',
  'quality',
  'quantum',
  'quarrel',
  'quarter',
  'queenly',
  'queerly',
  'quicken',
  'quicker',
  'quickly',
  'quieter',
  'quietly',
  'quilter',
  'quinine',
  'quintet',
  'quitter',
  'raccoon',
  'racecar',
  'raceway',
  'radiant',
  'radical',
  'ragtime',
  'ragweed',
  'railcar',
  'rainbow',
  'rambler',
  'rampant',
  'rampart',
  'rancher',
  'raisins',
  'ransack',
  'rapidly',
  'rapport',
  'rapture',
  'rattler',
  'ravioli',
  'reactor',
  'reality',
  'realize',
  'rebuild',
  'receipt',
  'receive',
  'recheck',
  'reclaim',
  'recline',
  'recover',
  'rectify',
  'recycle',
  'redbull',
  'redcoat',
  'redhead',
  'redline',
  'redneck',
  'redness',
  'redtail',
  'reducer',
  'redwood',
  'referee',
  'reflect',
  'refocus',
  'refract',
  'refrain',
  'refresh',
  'refugee',
  'refusal',
  'refutal',
  'regatta',
  'regimen',
  'regular',
  'reissue',
  'rejoice',
  'related',
  'relaxer',
  'relearn',
  'release',
  'remount',
  'reptile',
  'rescued',
  'rescuer',
  'reserve',
  'respect',
  'respond',
  'restate',
  'restore',
  'reteach',
  'retrain',
  'reunion',
  'reunite',
  'revolve',
  'ripcurl',
  'riptide',
  'riviera',
  'rosebud',
  'routine',
  'rowboat',
  'rugrats',
  'sabbath',
  'sandman',
  'sarcasm',
  'satisfy',
  'scandal',
  'scenery',
  'scholar',
  'seabass',
  'seabird',
  'seafood',
  'seafowl',
  'seagull',
  'sealant',
  'seaport',
  'seasick',
  'seaside',
  'seasons',
  'seawall',
  'seaward',
  'seaweed',
  'secrecy',
  'section',
  'secular',
  'secured',
  'serious',
  'selfish',
  'sellout',
  'sensors',
  'servant',
  'service',
  'setback',
  'settler',
  'seventy',
  'several',
  'shelter',
  'sheriff',
  'sherman',
  'shifter',
  'shirley',
  'shooter',
  'shorter',
  'sidearm',
  'sincere',
  'sitdown',
  'sixteen',
  'skijump',
  'skimask',
  'skipole',
  'skypark',
  'slavery',
  'smarter',
  'smuggle',
  'snowman',
  'snuggle',
  'society',
  'soldier',
  'someday',
  'speaker',
  'special',
  'species',
  'sponsor',
  'stadium',
  'stamina',
  'standup',
  'staples',
  'station',
  'storage',
  'stories',
  'stirfry',
  'stirrup',
  'streams',
  'strides',
  'student',
  'subject',
  'subsist',
  'subtext',
  'succeed',
  'success',
  'sucrose',
  'suggest',
  'suicide',
  'sulphur',
  'summary',
  'sunbath',
  'sunbelt',
  'sunbeam',
  'sunbelt',
  'sunburn',
  'sundays',
  'sundeck',
  'sundial',
  'sundown',
  'sunfish',
  'sunglow',
  'sunlamp',
  'sunland',
  'sunlike',
  'sunmaid',
  'sunrise',
  'sunroof',
  'sunroom',
  'sunsets',
  'sunspot',
  'support',
  'suppose',
  'supreme',
  'surgeon',
  'surgery',
  'survive',
  'suspend',
  'sweater',
  'swimmer',
  'swollen',
  'symptom',
  'tablets',
  'tabloid',
  'tadpole',
  'talents',
  'tangent',
  'tangled',
  'taxcuts',
  'teacher',
  'teenage',
  'tension',
  'tequila',
  'termite',
  'terrify',
  'testify',
  'textile',
  'theatre',
  'therapy',
  'thermos',
  'thinker',
  'thirsty',
  'thought',
  'thunder',
  'timeout',
  'tubacco',
  'toenail',
  'toering',
  'tonight',
  'tonnage',
  'topanga',
  'topdeck',
  'topless',
  'topload',
  'topples',
  'toprack',
  'topside',
  'topsoil',
  'tornado',
  'torture',
  'tourist',
  'towboat',
  'towhead',
  'tractor',
  'traitor',
  'transit',
  'treetop',
  'tribute',
  'trouble',
  'trouser',
  'trucker',
  'trumpet',
  'tuesday',
  'tunisia',
  'twinkle',
  'twister',
  'typhoid',
  'typical',
  'tyranny',
  'unhappy',
  'unheard',
  'unhinge',
  'unhitch',
  'unicorn',
  'unified',
  'uniform',
  'unitard',
  'unitary',
  'unkempt',
  'unknown',
  'unlatch',
  'unlearn',
  'unleash',
  'unlevel',
  'unloose',
  'unlucky',
  'unquote',
  'unravel',
  'unready',
  'unscrew',
  'unstack',
  'unusual',
  'unwound',
  'unwoven',
  'updraft',
  'upfront',
  'upgrade',
  'upright',
  'upscale',
  'upstair',
  'upstate',
  'upsurge',
  'upswell',
  'upswept',
  'upswing',
  'uptight',
  'uranium',
  'urgency',
  'urinary',
  'urinate',
  'urology',
  'usually',
  'utensil',
  'utility',
  'utopian',
  'vacancy',
  'vaccine',
  'vagrant',
  'vaguely',
  'valance',
  'valuate',
  'valleys',
  'vampire',
  'vanilla',
  'vanload',
  'vanpool',
  'variant',
  'variety',
  'vehicle',
  'velvety',
  'venison',
  'venture',
  'verdict',
  'version',
  'vibrate',
  'viceroy',
  'victory',
  'village',
  'vintage',
  'violate',
  'violent',
  'visitor',
  'volcano',
  'waffles',
  'walnuts',
  'warbler',
  'warfare',
  'warrior',
  'washers',
  'wealthy',
  'weather',
  'webcast',
  'website',
  'webster',
  'weekend',
  'welcome',
  'welfare',
  'western',
  'wetness',
  'wetsuit',
  'whoever',
  'windows',
  'winners',
  'wipeout',
  'wiseman',
  'without',
  'witness',
  'worldly',
  'worship',
  'wrestle',
  'wronged',
  'zealous',
];
