import { useLocation } from 'react-router-dom';
import React from 'react';

export const NotFound = () => {
  const location = useLocation();
  return (
    <div>
      Wherever you're trying to go... we didn't find it :(
      <br />({JSON.stringify(location)})
    </div>
  );
};
