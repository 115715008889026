import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import dayjs from 'dayjs';
import { NavigationButtonList } from '../components/navigationButtonList';
import { getActiveSolution } from '../lib/data/getActiveSolution';
import ReactGA from 'react-ga4';

// TODO(paulmest): probably move this type to prevent circular references?
export type ActiveSolution = {
  slug: string;
  solution: string;
  hint: string;
  description: string;
  nextDate: Date;
};

export const ActiveSolutionContext = createContext<ActiveSolution | null>(null);
ActiveSolutionContext.displayName = 'ActiveSolutionContext';

export const useActiveSolution = () => useContext(ActiveSolutionContext) as ActiveSolution;

type Props = {
  children?: ReactNode;
};

const TheFuture = () => {
  useEffect(() => {
    ReactGA.event('future');
  }, []);
  return (
    <div>
      Whoa! What's life like in the future??
      <img style={{ maxWidth: '100%' }} src="/images/futuristic-city.jpg" alt="Futuristic city" />
      <NavigationButtonList />
    </div>
  );
};

export const ActiveSolutionProvider = ({ children }: Props) => {
  const params = useParams();
  // if they visit the index route, just use today's date for the slug
  const defaultSlug = dayjs().format('YYYY-MM-DD');
  const slug = params.slug || defaultSlug;
  const { value, error, loading } = useAsync(() => getActiveSolution(slug), [slug]);
  const parsedSlug = dayjs(slug);
  if (parsedSlug.isValid()) {
    // this is a valid date, let's make sure it's not in the future
    if (parsedSlug > dayjs()) {
      return <TheFuture />;
    }
  }

  if (loading) return <div>Loading....</div>;
  if (error)
    return (
      <div>
        Something went wrong: {error.message}
        <NavigationButtonList />
      </div>
    );

  if (value) {
    return (
      <ActiveSolutionContext.Provider
        value={{
          ...value,
          solution: value.solution.toUpperCase(),
          nextDate: dayjs().startOf('day').add(1, 'day').toDate(),
        }}
      >
        {children}
      </ActiveSolutionContext.Provider>
    );
  }
  return <div>this shouldn't happen</div>;
};
