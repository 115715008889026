import { NextWordCountdown } from './NextWordCountdown';
import { shareStatus } from '../../lib/share';
import { SHARE_TEXT } from '../../constants/strings';
import { FaShareAlt } from 'react-icons/fa';
import { GameStats } from '../../lib/localStorage';
import { useActiveSolution } from '../../context/ActiveSolutionContext';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

type Props = {
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  handleShare: () => void;
  isHardMode: boolean;
};

const isTodaysWord = (slug: string) => {
  const todayYmd = dayjs().format('YYYY-MM-DD');
  return slug === todayYmd;
};

export const CountdownAndShareFooter = ({
  guesses,
  isGameLost,
  isHardMode,
  handleShare,
}: Props) => {
  const activeSolution = useActiveSolution();
  return (
    <div className="mt-0 sm:mt-0 dark:text-white">
      <div className="mt-0 sm:mt-0 columns-2">
        {isTodaysWord(activeSolution.slug) ? (
          <NextWordCountdown />
        ) : (
          <div>
            <Link to="/weddingle">Play today's Weddingle!</Link>
          </div>
        )}
        <button
          type="button"
          className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => {
            void shareStatus(
              guesses,
              isGameLost,
              isHardMode,
              activeSolution.solution,
              activeSolution.slug,
              handleShare
            );
          }}
        >
          {SHARE_TEXT} <FaShareAlt className="inline" />
        </button>
      </div>
    </div>
  );
};
