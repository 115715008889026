import { useActiveSolution } from '../../context/ActiveSolutionContext';
import { NEW_WORD_TEXT } from '../../constants/strings';
import Countdown from 'react-countdown';

export const NextWordCountdown = () => {
  const activeSolution = useActiveSolution();
  return (
    <div>
      <h5 className="dark:text-white">{NEW_WORD_TEXT}</h5>
      <Countdown
        className="text-lg font-medium text-gray-900 dark:text-gray-100"
        date={activeSolution.nextDate}
        daysInHours={true}
      />
    </div>
  );
};
