import { useAsync } from 'react-use';
import { getDbWorker } from '../../lib/data/getDbWorker';

const getWords = async (): Promise<string> => {
  const worker = await getDbWorker();
  const result = await worker.db.query(`SELECT *
                                        FROM words`);

  return JSON.stringify(result);
};

export const DataViewer2 = () => {
  const { value, error, loading } = useAsync(getWords);
  if (loading) return <div>Loading....</div>;
  if (error) return <div>Something went wrong: ${error.message}</div>;
  if (value)
    return (
      <div>
        <strong>Words:</strong>
        <pre>{JSON.stringify(value, null, 2)}</pre>
      </div>
    );
  return <div>I'm a data viewer!</div>;
};
