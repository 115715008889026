import { Cell } from './Cell';
import { useActiveSolution } from '../../context/ActiveSolutionContext';

type Props = {
  guess: string;
  className: string;
};

export const CurrentRow = ({ guess, className }: Props) => {
  const activeSolution = useActiveSolution();
  const splitGuess = guess.split('');
  const emptyCells = Array.from(
    Array(activeSolution.solution.length - splitGuess.length)
  );
  const classes = `flex justify-center mb-1 ${className}`;

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  );
};
