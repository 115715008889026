import { WORDS } from '../constants/wordlist7';
import { VALID_GUESSES } from '../constants/validGuesses7';
import {
  WRONG_SPOT_MESSAGE,
  NOT_CONTAINED_MESSAGE,
} from '../constants/strings';
import { getGuessStatuses } from './statuses';

export const isWordInWordList = (word: string) => {
  console.log(word);

  // We are always returning true here so that people can just hack the system
  // with nonsensical responses and get to the answer.
  return (
    WORDS.includes(word.toUpperCase()) ||
    VALID_GUESSES.includes(word.toUpperCase()) ||
    true
  );
};

export const isWinningWord = (word: string, solution: string) => {
  return solution === word;
};

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (
  word: string,
  guesses: string[],
  solution: string
) => {
  if (guesses.length === 0) {
    return false;
  }

  const lettersLeftArray = new Array<string>();
  const guess = guesses[guesses.length - 1];
  const statuses = getGuessStatuses(guess, solution);

  for (let i = 0; i < guess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(guess[i]);
    }
    if (statuses[i] === 'correct' && word[i] !== guess[i]) {
      return WRONG_SPOT_MESSAGE(guess[i], i + 1);
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n;
  for (const letter of word) {
    n = lettersLeftArray.indexOf(letter);
    if (n !== -1) {
      lettersLeftArray.splice(n, 1);
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0]);
  }
  return false;
};

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf();
  const now = Date.now();
  const msInDay = 24 * 60 * 60 * 1000; // 86400000
  const index = Math.floor((now - epochMs) / msInDay);
  const nextDay = (index + 1) * msInDay + epochMs;

  return {
    solution: WORDS[index % WORDS.length].toUpperCase(),
    solutionIndex: index,
    tomorrow: nextDay,
  };
};

export const getSpecialWord = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf();
  const now = Date.now();
  const msInDay = 24 * 60 * 60 * 1000; // 86400000
  const index = Math.floor((now - epochMs) / msInDay);
  const nextDay = (index + 1) * msInDay + epochMs;

  return {
    // solution: 'BESTMAN'.toUpperCase(),
    // solution: 'officiant'.toUpperCase(),
    solution: 'groomsman'.toUpperCase(),
    solutionIndex: 1,
    tomorrow: nextDay,
  };
};

// export const { solution, solutionIndex, tomorrow } = getSpecialWord();
