/*
 * Copied from
 * https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
 * And referenced https://github.com/PriceRunner/react-ga4/blob/master/src/ga4.test.js#L425
 * */
import ReactGA from 'react-ga4';

export const sendToGoogleAnalytics = (data: any) => {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  const { name, delta, value, id } = data;
  const payload = {
    hitType: 'event',
    eventCategory: 'Web Vitals',
    eventAction: name,
    nonInteraction: true,
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  };
  ReactGA.send(payload);
};
