import { Outlet, Route, Routes } from 'react-router-dom';
import { NavigationButtonList } from './components/navigationButtonList';
import { WeddinglePage } from './pages/Weddingle';
import { DataViewer } from './components/data/DataViewer';
import { DataViewer2 } from './components/data/DataViewer2';
import React from 'react';
import { DebugPage } from './pages/DebugPage';
import { NotFound } from './pages/NotFound';

const TeamPolgaHome = () => (
  <div>
    <Outlet />
  </div>
);

const SlugFest = () => {
  // const params = useParams();
  // return <div style={{ color: 'orange' }}>HI {params.slug}!</div>;
  return null;
};

export const TeamPolgaRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TeamPolgaHome />}>
        <Route index element={<NavigationButtonList />} />
      </Route>
      <Route path="*" element={<NotFound />} />
      <Route path="weddingle" element={<WeddinglePage />}>
        <Route path=":slug" element={<SlugFest />} />
      </Route>
      <Route path="debug" element={<DebugPage />}>
        <Route path="db" element={<DataViewer />} />
        <Route path="db2" element={<DataViewer2 />} />
      </Route>
    </Routes>
  );
};
