import { getDbWorker } from './getDbWorker';
import { ActiveSolution } from '../../context/ActiveSolutionContext';

export const getActiveSolution = async (slug: string) => {
  const worker = await getDbWorker();

  const result = (await worker.db.query(`SELECT *
                                         FROM words
                                         WHERE slug = '${slug}'`)) as Object[];
  if (result.length > 1) {
    throw new Error(`Too many records: ${result.length}`);
  }
  if (result.length === 0) {
    throw new Error(`No records match "slug = ${slug}"`);
  }
  // TODO(paulmest): set nextDate?
  return result[0] as ActiveSolution;
};

export const getQueryResults = async (sql: string) => {
  const worker = await getDbWorker();

  const result = (await worker.db.query(sql)) as Object[];
  return result;
};
