import { AlertProvider } from '../context/AlertContext';
import Wordle from '../Wordle';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ActiveSolutionProvider } from '../context/ActiveSolutionContext';

export function WeddinglePage() {
  return (
    <ActiveSolutionProvider>
      <AlertProvider>
        <Wordle />
        <Outlet />
      </AlertProvider>
    </ActiveSolutionProvider>
  );
}
