import { Link, Outlet } from 'react-router-dom';
import React from 'react';

export const DebugPage = () => {
  return (
    <div>
      <span>{<Link to="/">Team Polga home</Link>}</span> |{' '}
      <span>{<Link to="db">DataViewer (sql.js)</Link>}</span> |{' '}
      <span>
        <Link to="db2">DataViewer2 (sql.js)</Link>
      </span>
      <br />
      <div>
        <Outlet />
      </div>
    </div>
  );
};
