import { getGuessStatuses } from '../../lib/statuses';
import { Cell } from './Cell';
import { useActiveSolution } from '../../context/ActiveSolutionContext';

type Props = {
  guess: string;
  isRevealing?: boolean;
};

export const CompletedRow = ({ guess, isRevealing }: Props) => {
  const activeSolution = useActiveSolution();
  const statuses = getGuessStatuses(guess, activeSolution.solution);

  return (
    <div className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
        />
      ))}
    </div>
  );
};
