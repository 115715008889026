import { ReactConfetti } from '@cleverpoint/react-confetti';
import { useEffect, useState } from 'react';

const constrainImage = (
  img: { width: number; height: number },
  maxWidth: number,
  maxHeight: number
) => {
  const width = img.width;
  const height = img.height;
  const ratio = width / height;
  const maxRatio = maxWidth / maxHeight;
  const constrainedWidth = maxRatio > ratio ? maxWidth : maxHeight * ratio;
  const constrainedHeight = maxRatio > ratio ? maxWidth / ratio : maxHeight;
  return { width: constrainedWidth, height: constrainedHeight };
};

export const JoshConfetti = () => {
  const [images, setImages] = useState<HTMLImageElement[] | undefined>();
  useEffect(() => {
    const imageSrcPaths = ['/images/josh1.png', '/images/josh2.png', '/images/josh3.png'];
    const myImages = imageSrcPaths.map((imageSrcPath) => {
      const img = new Image();
      img.src = imageSrcPath;
      return img;
    });
    setImages(myImages);
  }, []);
  return (
    <ReactConfetti
      drawShape={(ctx, shapeVariant) => {
        const img = images![shapeVariant];
        const constrained = constrainImage(img, 50, 50);
        ctx.drawImage(img, 0, 0, constrained.width, constrained.height);
      }}
      width={400}
      height={1000}
      numberOfPieces={10}
      tweenDuration={10000}
      angularSpin={0.02}
      rotationVelocity={0.01}
      initialVelocityY={1}
    />
  );
};
