import { getGuessStatuses } from './statuses';
import { GAME_TITLE } from '../constants/strings';
import { getStoredIsHighContrastMode } from './localStorage';
import { MAX_CHALLENGES } from '../constants/settings';

const copyToClipboard = (textToCopy: string) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};

export const shareStatus = async (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  solution: string,
  slug: string,
  handleShare: Function
) => {
  // TODO(paulmest): Add custom share message to SQLite and retrieve here?
  // const customMessage =
  //   '\n\nChoose one: ☑️\n\n⬛️ You sure are needy, but I guess so.\n\n⬛️ Nah, I have enough responsibilities as it is.';
  const customMessage = '';
  const shareGame = `${GAME_TITLE} - ${slug} - ${
    lost ? 'X' : guesses.length
  }/${MAX_CHALLENGES}${isHardMode ? '*' : ''}\n\n${generateEmojiGrid(
    guesses,
    solution
  )}\n`;
  const canonicalUrl = `https://teampolga.cleverpoint.co/weddingle/`;
  const fullText = `${shareGame}${customMessage}`;
  const shareData = {
    title: `Weddingle - ${slug} | Team Polga`,
    text: fullText,
    url: canonicalUrl,
  };

  try {
    await navigator.share(shareData);
  } catch (err) {
    await copyToClipboard(fullText + canonicalUrl);
    handleShare();
  }
};

export const generateEmojiGrid = (guesses: string[], solution: string) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess, solution);
      const isHighContrast = getStoredIsHighContrastMode();
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              if (isHighContrast) {
                return '🟧';
              }
              return '🟩';
            case 'present':
              if (isHighContrast) {
                return '🟦';
              }
              return '🟨';
            default:
              if (localStorage.getItem('theme') === 'dark') {
                return '⬛';
              }
              return '⬜';
          }
        })
        .join('');
    })
    .join('\n');
};
