import { createDbWorker, WorkerHttpvfs } from 'sql.js-httpvfs';

const workerUrl = new URL(
  'sql.js-httpvfs/dist/sqlite.worker.js',
  import.meta.url
);

const wasmUrl = new URL('sql.js-httpvfs/dist/sql-wasm.wasm', import.meta.url);

let dbWorker: WorkerHttpvfs | undefined;

export const getDbWorker = async () => {
  if (dbWorker) {
    return dbWorker;
  }
  const worker = await createDbWorker(
    [
      {
        from: 'inline',
        config: {
          serverMode: 'full',
          url: '/data/weddingle.sqlite3',
          requestChunkSize: 4096,
        },
      },
    ],
    workerUrl.toString(),
    wasmUrl.toString()
  );
  dbWorker = worker;
  return worker;
};
