import { Link } from 'react-router-dom';
import React from 'react';

export const NavigationButtonList = () => (
  <div>
    <div>
      <Link
        to="/weddingle"
        className="link-button bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Play Weddingle
      </Link>
    </div>
    <a
      className="link-button bg-amber-600 hover:bg-amber-700 focus:ring-amber-500"
      href="https://www.withjoy.com/teampolga"
      target="_blank"
      rel="noreferrer"
    >
      Visit Withjoy (to update RSVP, select entrees, view schedule)
    </a>
  </div>
);
