"use strict";
/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDbWorker = void 0;
// TODO: using comlink for all this is a pretty ugly hack
const Comlink = __importStar(require("comlink"));
Comlink.transferHandlers.set("WORKERSQLPROXIES", {
    canHandle: (obj) => false,
    serialize(obj) {
        throw Error("no");
    },
    deserialize: (port) => {
        port.start();
        return Comlink.wrap(port);
    },
});
async function createDbWorker(configs, workerUrl, wasmUrl) {
    const worker = new Worker(workerUrl);
    const sqlite = Comlink.wrap(worker);
    const db = (await sqlite.SplitFileHttpDatabase(wasmUrl, configs));
    worker.addEventListener("message", handleAsyncRequestFromWorkerThread);
    return { db, worker: sqlite, configs };
}
exports.createDbWorker = createDbWorker;
async function handleAsyncRequestFromWorkerThread(ev) {
    if (ev.data && ev.data.action === "eval") {
        const metaArray = new Int32Array(ev.data.notify, 0, 2);
        const dataArray = new Uint8Array(ev.data.notify, 2 * 4);
        let response;
        try {
            response = { ok: await handleDomVtableRequest(ev.data.request) };
        }
        catch (e) {
            console.error("worker request error", ev.data.request, e);
            response = { err: String(e) };
        }
        const text = new TextEncoder().encode(JSON.stringify(response));
        dataArray.set(text, 0); // need to copy here because:
        // sadly TextEncoder.encodeInto: Argument 2 can't be a SharedArrayBuffer or an ArrayBufferView backed by a SharedArrayBuffer [AllowShared]
        // otherwise this would be better:
        /*const encodeRes = new TextEncoder().encodeInto(response, data);
        if (encodeRes.read !== response.length) {
          console.log(encodeRes, response.length)
          throw Error(`not enough space for response: ${response.length} > ${data.byteLength}`);
        }*/
        metaArray[1] = text.length;
        Atomics.notify(metaArray, 0);
    }
}
function getUniqueSelector(elm) {
    if (elm.tagName === "BODY")
        return "body";
    const names = [];
    while (elm.parentElement && elm.tagName !== "BODY") {
        if (elm.id) {
            // assume id is unique (which it isn't)
            names.unshift("#" + elm.id);
            break;
        }
        else {
            let c = 1;
            let e = elm;
            while (e.previousElementSibling) {
                e = e.previousElementSibling;
                c++;
            }
            names.unshift(elm.tagName.toLowerCase() + ":nth-child(" + c + ")");
        }
        elm = elm.parentElement;
    }
    return names.join(" > ");
}
function keys(o) {
    return Object.keys(o);
}
async function handleDomVtableRequest(req) {
    console.log("dom vtable request", req);
    if (req.type === "select") {
        return [...document.querySelectorAll(req.selector)].map((e) => {
            const out = {};
            for (const column of req.columns) {
                if (column === "selector") {
                    out.selector = getUniqueSelector(e);
                }
                else if (column === "parent") {
                    if (e.parentElement) {
                        out.parent = e.parentElement
                            ? getUniqueSelector(e.parentElement)
                            : null;
                    }
                }
                else if (column === "idx") {
                    // ignore
                }
                else {
                    out[column] = e[column];
                }
            }
            return out;
        });
    }
    else if (req.type === "insert") {
        if (!req.value.parent)
            throw Error(`"parent" column must be set when inserting`);
        const target = document.querySelectorAll(req.value.parent);
        if (target.length === 0)
            throw Error(`Parent element ${req.value.parent} could not be found`);
        if (target.length > 1)
            throw Error(`Parent element ${req.value.parent} ambiguous (${target.length} results)`);
        const parent = target[0];
        if (!req.value.tagName)
            throw Error(`tagName must be set for inserting`);
        const ele = document.createElement(req.value.tagName);
        const cantSet = ["idx"];
        for (const i of keys(req.value)) {
            if (req.value[i] !== null) {
                if (i === "tagName" || i === "parent")
                    continue;
                if (i === "idx" || i === "selector")
                    throw Error(`${i} can't be set`);
                ele[i] = req.value[i];
            }
        }
        parent.appendChild(ele);
        return null;
    }
    else if (req.type === "update") {
        const targetElement = document.querySelector(req.value.selector);
        if (!targetElement)
            throw Error(`Element ${req.value.selector} not found!`);
        const toSet = [];
        for (const k of keys(req.value)) {
            const v = req.value[k];
            if (k === "parent") {
                if (v !== getUniqueSelector(targetElement.parentElement)) {
                    const targetParent = document.querySelectorAll(v);
                    if (targetParent.length !== 1)
                        throw Error(`Invalid target parent: found ${targetParent.length} matches`);
                    targetParent[0].appendChild(targetElement);
                }
                continue;
            }
            if (k === "idx" || k === "selector")
                continue;
            if (v !== targetElement[k]) {
                console.log("SETTING ", k, targetElement[k], "->", v);
                if (k === "tagName")
                    throw Error("can't change tagName");
                toSet.push(k); // defer setting to prevent setting multiple interdependent values (e.g. textContent and innerHTML)
            }
        }
        for (const k of toSet) {
            targetElement[k] = req.value[k];
        }
        return null;
    }
    else {
        throw Error(`unknown request ${req.type}`);
    }
}
