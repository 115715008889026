import { StatBar } from '../stats/StatBar';
import { Histogram } from '../stats/Histogram';
import { GameStats } from '../../lib/localStorage';
import { BaseModal } from './BaseModal';
import {
  GUESS_DISTRIBUTION_TEXT,
  STATISTICS_TITLE,
} from '../../constants/strings';
import { CountdownAndShareFooter } from './CountdownAndShareFooter';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: () => void;
  isHardMode: boolean;
};

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStats,
  isGameLost,
  isGameWon,
  ...rest
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
      </BaseModal>
    );
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram gameStats={gameStats} />
      {(isGameLost || isGameWon) && (
        <CountdownAndShareFooter
          gameStats={gameStats}
          isGameLost={isGameLost}
          {...rest}
        />
      )}
    </BaseModal>
  );
};
