// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import initSqlJs from 'sql.js';
import { getDbWorker } from '../../lib/data/getDbWorker';
import { getQueryResults } from '../../lib/data/getActiveSolution';

export const DataViewer = () => {
  const [db, setDb] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getWorker = async () => {
      console.log('getWorker');
      const worker = await getDbWorker();
      setDb(worker);
      console.log('worker set');
    };
    void getWorker();
    // sql.js needs to fetch its wasm file, so we cannot immediately instantiate the database
    // without any configuration, initSqlJs will fetch the wasm files directly from the same path as the js
    // see ../craco.config.js
    // try {
    //   const SQL = await initSqlJs({
    //     locateFile: (file) =>
    //       // new URL('sql.js/dist/sql-wasm.wasm', import.meta.url),
    //       new URL('sql.js-httpvfs/dist/sql-wasm.wasm', import.meta.url),
    //   });
    //   setDb(new SQL.Database());
    // } catch (err) {
    //   setError(err);
    // }
  }, []);

  // if (error) return <pre className="white-text">{error.toString()}</pre>;
  // else
  if (!db) return <pre>Loading...</pre>;
  else return <SQLRepl db={db} />;
};

/**
 * A simple SQL read-eval-print-loop
 * @param {{db: import("sql.js").Database}} props
 */
function SQLRepl({ db }) {
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  function exec(sql) {
    try {
      // The sql is executed synchronously on the UI thread.
      // You may want to use a web worker here instead
      getQueryResults(sql).then((results) => {
        setResults(results);
      });
      // setResults(db.db.query(sql)); // an array of objects is returned
      setError(null);
    } catch (err) {
      // exec throws an error when the SQL statement is invalid
      setError(err);
      setResults([]);
    }
  }

  return (
    <div className="App">
      <h1>React SQL interpreter</h1>

      <textarea
        className="text-black"
        // onChange={(e) => exec(e.target.value)}
        onChange={(e) => exec('SELECT * FROM words;')}
        placeholder="Enter some SQL. No inspiration ? Try “select sqlite_version()”"
      ></textarea>

      <pre className="error">{(error || '').toString()}</pre>

      <pre>
        {
          // results contains one object per select statement in the query
          results ? <ResultsTable results={results} /> : <div>nothing</div>
        }
      </pre>
    </div>
  );
}

/**
 * Renders a single value of the array returned by db.exec(...) as a table
 * @param {import("sql.js").QueryExecResult} props
 */
function ResultsTable({ results }) {
  if (results?.length === 0) {
    return (
      <div className="bg-indigo-500 bg-indigo-200 bg-indigo-300 bg-indigo-400">
        nada
      </div>
    );
  }
  return (
    <table className="table-auto">
      <thead>
        <tr>
          {Object.keys(results[0]).map((key, i) => (
            <th key={i} className="max-w-sm border-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {
          // values is an array of arrays representing the results of the query
          results?.map((row, i) => (
            <tr key={i}>
              {Object.values(row).map((value, j) => {
                return (
                  <td
                    key={j}
                    className={`max-w-xl bg-indigo-${
                      j * 100 + 200
                    } border-2 whitespace-pre-wrap`}
                  >
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                    {/*{value}*/}
                  </td>
                );
              })}
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}
