import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';

import reportWebVitals from './reportWebVitals';

import ReactGA from 'react-ga4';

import { BrowserRouter, useLocation } from 'react-router-dom';
import { sendToGoogleAnalytics } from './lib/googleAnalytics4';
import { useDarkMode } from './hooks/useDarkMode';
import { TeamPolgaRoutes } from './TeamPolgaRoutes';

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID!);
}

const App = () => {
  useDarkMode();
  const location = useLocation();
  React.useEffect(() => {
    //TODO(paulmest): add searchpath if exists?
    ReactGA.send({ hitType: 'pageview', path: location.pathname });
  }, [location]);
  return <TeamPolgaRoutes />;
};

const container = document.getElementById('root') as HTMLDivElement;
container.classList.add('dark:text-white');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
