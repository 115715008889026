import { GameStats } from '../../lib/localStorage';
import { BaseModal } from './BaseModal';
import { useActiveSolution } from '../../context/ActiveSolutionContext';
import { CountdownAndShareFooter } from './CountdownAndShareFooter';
import { JoshConfetti } from './JoshConfetti';
import { ModalConfetti } from './ModalConfetti';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: () => void;
  isHardMode: boolean;
};

export const SuccessModal = ({ isOpen, handleClose, ...rest }: Props) => {
  const activeSolution = useActiveSolution();
  return (
    // consider replace the title with the slug?
    <BaseModal title="You got it!" isOpen={isOpen} handleClose={handleClose}>
      {activeSolution.solution === 'OFFICIANT' ? <JoshConfetti /> : <ModalConfetti />}
      <h4 className="text-md leading-1 font-small text-gray-900 dark:text-gray-100">
        {/*  // TODO(paulmest): change here and title above */}
        <div dangerouslySetInnerHTML={{ __html: activeSolution.description }} />
      </h4>
      <br />
      <CountdownAndShareFooter {...rest} />
    </BaseModal>
  );
};
