const gameStateKey = 'gameState';
const highContrastKey = 'highContrast';

type StoredGameState = {
  guesses: string[];
  solution: string;
};

/**
 * Game state refers to each of the solutions guesses and whether the game
 * is in progress, won, or lost
 */
const getStateKey = (slug: string) => {
  return `${gameStateKey}-${slug}`;
};

const infoModalKey = 'info-modal';

export const loadHasSeenInfoModal = () => {
  const value = localStorage.getItem(infoModalKey);
  if (value) return JSON.parse(value);

  return false;
};
export const setHasSeenInfoModal = (value: boolean) =>
  localStorage.setItem(infoModalKey, JSON.stringify(value));

export const clearGameState = (slug: string) => {
  console.log('clearing game state', getStateKey(slug));
  localStorage.removeItem(getStateKey(slug));
};

export const saveGameStateToLocalStorage = (
  slug: string,
  gameState: StoredGameState
) => {
  localStorage.setItem(getStateKey(slug), JSON.stringify(gameState));
};

export const loadGameStateFromLocalStorage = (slug: string) => {
  const state = localStorage.getItem(getStateKey(slug));
  return state ? (JSON.parse(state) as StoredGameState) : null;
};

const gameStatKey = 'gameStats';

export type GameStats = {
  winDistribution: number[];
  gamesFailed: number;
  currentStreak: number;
  bestStreak: number;
  totalGames: number;
  successRate: number;
};

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
};

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey);
  return stats ? (JSON.parse(stats) as GameStats) : null;
};

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1');
  } else {
    localStorage.removeItem(highContrastKey);
  }
};

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey);
  return highContrast === '1';
};
